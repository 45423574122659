<template>
  <div class="bg-gray-50 rounded-lg p-8 gap-5 flex flex-col-reverse lg:flex-row print:overflow-auto">
    <div class="w-full lg:w-1/3">
      <div class="shadow-current mb-4 rounded-lg p-3 bg-white border-2 border-pink-600 shadow-sm">
        <header class="flex flex-wrap items-center mb-2">
          <h4 class="text-sm font-bold mr-2 align-middle">Current</h4>
          <dashboard-risk-badge :risk="currentConfiguration?.risk" :badgeStyle="'medium'" />
        </header>
        <main class="flex flex-wrap justify-between items-baseline">
          <div v-for="setting in currentConfiguration?.settings" :key="setting.key">
            <dd class="flex justify-between items-baseline md:block lg:flex grid grid-flow-row auto-rows-max">
              <dl class="m-1" :title="setting.fullLabel">
                <dd class="text-sm font-bold">{{ setting.propertyLabel }}</dd>
                <dt class="text-xs font-normal text-gray-500">{{ setting.shortLabel }}</dt>
              </dl>
            </dd>
          </div>
        </main>
      </div>

      <div class="pt-1 flex flex-wrap items-center justify-between">
        <h2 class="text-gray-800 font-semibold text-lg">Compare configurations</h2>
        <button @click="showAddConfigurationModal = true" class="text-pink-600 text-sm underline hover:no-underline print:hidden">+ Add Configuration</button>
      </div>
      <div class="m-2 text-xs text-gray-500">
        <a target="_blank" href="https://help.particleone.com/reference-guides/learn-more/"
          class="text-pink-600 underline hover:no-underline">Learn more</a> about the mitigation options
      </div>
      <add-configuration-form
        :showModal="showAddConfigurationModal"
        :spaceName="selectedSpace?.name"
        @close="showAddConfigurationModal = false"
      />

      <div class="max-h-60v mt-2 p-4 overflow-auto">
        <dashboard-configuration-card
          v-for="(configuration, index) in configurations"
          :configuration="configuration"
          :currentConfiguration="currentConfiguration"
          :isSelected="index === selectedConfigurationIndex"
          :key="configuration?.key"
          :isLoading="loadingConfigurationId === configuration.id"
          :letter="letter(index + 1)"
          @click="selectedConfigurationIndex = index"
          @add="add(configuration, index)"
          @remove="remove(configuration, index)"
          @swap="swap(configuration, index)"
        />
      </div>
    </div>
    <div class="w-full lg:w-2/3 px-4 py-5 items-stretch">
      <h4 class="font-bold text-xl text-gray-800 mb-1">Risk index</h4>
      <p class="text-sm">
        This risk index plot displays how <strong>current</strong> and <strong>alternative</strong> configuration risk indices vary over time. The variation in risk index is due to the fluctuations of the background infection rates. <a target="_blank" class="text-pink-600 underline hover:no-underline" href="https://help.particleone.com/risk-index-for-a-space/">Learn more</a>
      </p>
      <dashboard-space-time-series-graph
        :selectedConfiguration="selectedConfigurationIndex+1"
        :currentConfiguration="currentConfigurationIndex"
        :data="dataToPlot"
      >
      </dashboard-space-time-series-graph>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import DashboardSpaceTimeSeriesGraph from './DashboardSpaceTimeSeriesGraph.vue';
import DashboardRiskBadge from './DashboardRiskBadge.vue';
import DashboardConfigurationCard from './DashboardConfigurationCard.vue';
import AddConfigurationForm from './AddConfigurationForm.vue';
import { ref } from 'vue';

export default {
  name: 'DashboardSpaceOverview',
  components: {
    DashboardSpaceTimeSeriesGraph,
    DashboardRiskBadge,
    DashboardConfigurationCard,
    AddConfigurationForm
  },
  setup() {
    const selectedConfigurationIndex = ref(0);
    const currentConfigurationIndex = ref(0);
    const loadingConfigurationId = ref(null);
    const showAddConfigurationModal = ref(false);

    return {
      selectedConfigurationIndex,
      currentConfigurationIndex,
      loadingConfigurationId,
      showAddConfigurationModal
    };
  },
  computed: {
    dataToPlot() {
      if (!this.configurations) {
        return [];
      }

      let data = this.configurations.map(this.createPlot);

      const scaledResultsData = this.scaledResults?.configurationResults(this.currentConfiguration?.getPropertyValueForKey('id'));
      data.unshift({name: 'Current', data: scaledResultsData ?? []});

      return data;
    },
    ...mapGetters(['configurations', 'currentConfiguration', 'scaledResults', 'selectedSpace', 'selectedBuilding'])
  },
  methods: {
    detailColor(setting) {
      return this.currentConfiguration?.isEqual(setting) ? 'text-gray-300' : 'text-gray-800';
    },
    titleColor(setting) {
      return this.currentConfiguration?.isEqual(setting) ? 'text-gray-200' : 'text-gray-400';
    },
    createPlot(configuration, index) {
      return {
        name: this.letter(index + 1),
        data: this.scaledResults?.configurationResults(configuration?.getPropertyValueForKey('id')) ?? []
      };
    },
    add() {},
    async remove(configurationToRemove) {
      this.loadingConfigurationId = configurationToRemove.id;
      await this.deleteConfiguration({
        space_id: this.$route.params.spaceId,
        configuration_ids: this.configurations
          .filter(configuration => configuration.id !== configurationToRemove.id)
          .map(configuration => configuration.id)
      });
      this.loadingConfigurationId = null;
    },
    async swap(configuration) {
      this.loadingConfigurationId = configuration.id;
      await this.swapConfigurations({
        configuration_id: configuration.id,
        space_id: this.$route.params.spaceId,
        current_configuration_id: this.currentConfiguration?.id
      });
      this.loadingConfigurationId = null;
    },
    letter(letterIndex) {
      return (letterIndex + 9).toString(36).toUpperCase();
    },
    ...mapActions(['swapConfigurations', 'deleteConfiguration'])
  }
};
</script>

<style>
</style>
